import { User } from '@shared/models'
import { createAction, props } from '@ngrx/store'

export const profileStoreKey = 'Profile'

export const LOAD_PROFILE_DATA = `[${ profileStoreKey }] Load profile data`
export const LOAD_PROFILE_DATA_SUCCESS = `[${ profileStoreKey }] Load profile data success`
export const LOAD_PROFILE_DATA_ERROR = `[${ profileStoreKey }] Load profile data error`

export const loadProfileData = createAction(LOAD_PROFILE_DATA, props<{ id: string }>())
export const loadProfileDataSuccess = createAction(LOAD_PROFILE_DATA_SUCCESS, props<{ data: User }>())
export const loadProfileDataError = createAction(LOAD_PROFILE_DATA_ERROR, props<{ error: string }>())
