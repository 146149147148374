import { createFeature, createReducer, on } from '@ngrx/store'
import { profileInitialState } from './profile.state'
import { loadProfileData, loadProfileDataError, loadProfileDataSuccess } from './profile.actions'

const profileReducer = createReducer(
  profileInitialState,
  on(loadProfileData, (state) => ({
    ...state,
    data: {
      ...state.data,
      loading: true
    }
  })),
  on(loadProfileDataSuccess, (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      loading: false,
      data
    }
  })),
  on(loadProfileDataError, (state, { error }) => ({
    ...state,
    data: {
      ...state.data,
      loading: false,
      error
    }
  }))
)

export const profileFeature = createFeature({
  name: 'profile',
  reducer: profileReducer
})
