import { User } from '@shared/models'

export interface ProfileState {
  // Data
  data: {
    loading: boolean
    error: string
    data: User
  }
  // Auth
  auth: {
    token: string | null
    expiredAt: string | null
  }
}

export const profileInitialState: ProfileState = {
  data: {
    loading: false,
    error: '',
    data: {} as User
  },
  auth: {
    token: null,
    expiredAt: null
  }
}
